import Vue              from 'vue'

import s_cabecalho      from './sections/cabecalho.vue';
import s_rodape         from './sections/rodape.vue';
import s_topicos        from './sections/topicos.vue';
import s_produtos       from './sections/produtos.vue';
import s_receitas       from './sections/receitas.vue';
import s_ebook          from './sections/ebook.vue';

Vue.component('s-cabecalho',            s_cabecalho);
Vue.component('s-rodape',               s_rodape);
Vue.component('s-topicos',              s_topicos);
Vue.component('s-produtos',             s_produtos);
Vue.component('s-receitas',             s_receitas);
Vue.component('s-ebook',                s_ebook);
