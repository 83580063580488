<template>
    <div>
        <s-cabecalho />

        <section class="conteudo">
            <div class="container">
                <div class="row py-3 py-sm-5">
                    <div class="col-12">
                        <h1>Quem Somos</h1>
                    </div>
                </div>

                <div class="row mb-5">
                    <div class="col-10  offset-1 col-sm-4 offset-sm-2">
                        <p>
                            <small>
                                <strong>
                                    A Miscela está se tornando um dos
                                    fornecedores de maior reconhecimento e
                                    credibilidade do setor de panificação e
                                    confeitaria do sul do país.
                                </strong>
                            </small>
                        </p>
                        <p>
                            <small>
                                Estamos há
                                <strong>mais de 10 anos</strong> desenvolvendo e
                                aprimorando pré misturas, através de alta
                                tecnologia e de uma equipe qualificada, para
                                melhor atendê-los.
                            </small>
                        </p>
                        <p>
                            <small>
                                Nossos produtos obtêm uma fórmula personalizada,
                                passando por um criterioso processo de
                                desenvolvimento e fabricação, chegando com
                                qualidade a mesa dos seus clientes.
                            </small>
                        </p>
                    </div>
                    <div class="col-10 offset-1 col-sm-4">
                        <p>
                            <small>
                                Hoje o parque industrial da Miscela está
                                localizado em Itajaí – Santa Catarina, e conta
                                com uma estrutura de mais de 2.500 m².
                            </small>
                        </p>
                        <p>
                            <small>
                                Estamos evoluindo constantemente, juntamente
                                com os nossos consumidores, clientes,
                                colaboradores e fornecedores, para ser uma
                                empresa mais completa.
                            </small>
                        </p>
                        <p>
                            <strong>
                                Entre em contato e conheça <br />
                                mais dos nossos produtos!
                            </strong>
                        </p>
                    </div>
                </div>
            </div>
        </section>

        <s-topicos />

        <section class="conteudo">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-sm-4 p-5 pb-0">
                        <h1>Missão</h1>
                        <p class="text-center fw-bold">
                            Desenvolver, produzir e comercializar produtos
                            alimentícios que envolvam as pessoas pelo sabor e
                            qualidade, garantindo a criação de valor e a
                            sustentabilidade do negócio.
                        </p>
                    </div>
                    <div class="col-12 col-sm-4 p-5 pb-0">
                        <h1>Visão</h1>
                        <p class="text-center fw-bold">
                            Estar entre os principais players do mercado e ser
                            referência de excelência em produtos alimentícios.
                        </p>
                    </div>
                    <div class="col-12 col-sm-4 p-5 pb-0">
                        <h1>Valores</h1>
                        <p class="text-center fw-bold">
                            SEGURANÇA das pessoas acima de tudo; PESSOAS
                            respeitadas, comprometidas e realizadas; EXCELÊNCIA
                            com SIMPLICIDADE; Foco na QUALIDADE;
                            SUSTENTABILIDADE econômica, social e ambiental
                        </p>
                    </div>
                </div>
            </div>
        </section>

        <s-rodape />
    </div>
</template>
<script>
export default {
    metaInfo: {
        title: "Sobre nós",
        meta: [
            { name: 'description', content: 'Fornecedor do ramo alimentício para confeitarias e padarias de Santa Catarina. Localizada em Itajaí há mais de 10 anos.' },
            { name: 'keywords', content: 'confeitaria; panificação; padaria; santa catarina; itajaí' },
        ],
    },
};
</script>