<template>
    <div>
        <s-cabecalho />

        <section id="receitas-lista" class="py-5">
            <div id="main"></div>
            <header>
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <h1 class="text-decoration-underline">
                                {{ receita.Titulo }}
                            </h1>
                            <p
                                class="fw-normal"
                                style="text-transform: initial"
                            >
                                <span class="text-uppercase fw-bold">{{
                                    receita.SubTitulo1
                                }}</span>

                                <span v-if="receita.SubTitulo2">
                                    <br />
                                    {{ receita.SubTitulo2 }}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </header>

            <div class="container">
                <div class="row">
                    <div class="col-10 offset-1 col-sm-12">
                        <div class="caixa receita">
                            <img :src="$sdk.prefix_images + receita.Imagem" class="receita-thumb" />
                            <h3>Ingredientes</h3>
                            <p v-html="receita.Ingredientes"></p>
                            <h3>Modo de Preparo</h3>
                            <p v-html="receita.ModoDePreparo"></p>

                            <div style="clear: both;"></div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <router-link :to="{ name: 'receitas', hash: '#main' }" class="voltar">
                        ← <span>Voltar a Todos as Receitas</span></router-link>
                    </div>
                </div>
            </div>
        </section>

        <s-ebook />

        <s-rodape />
    </div>
</template>
<script>
export default {
    data: function () {
        return {
            receita: {
                Titulo: "Título de Receita 1",
                SubTitulo1: "5kg 123",
                SubTitulo2: "Morango",
                Ingredientes:
                    "<p><strong>PARA ESPONJA:</strong></p><p>7,5kg de Farinha de Trigo Para Panifica&ccedil;&atilde;o<br />3,450 a 3,750 litros de &Aacute;gua Fria<br />400 a 500g de Fermento Biol&oacute;gico seco (para massa doce)<br />1,100 a 1,250Kg de Fermento Biol&oacute;gico &uacute;mido</p><p><strong> PARA REFOR&Ccedil;O:</strong></p><p>17,5kg de Farinha de Trigo Para Panifica&ccedil;&atilde;o<br />10kg de Mistura para Panetone em Pasta Com gema Miscela<br />130 und Gema de Ovo<br />9 a 10L de &Aacute;gua Gelada (adicionar at&eacute; o ponto desejado)</p><p><strong>FINALIZA&Ccedil;&Atilde;O:</strong><br />4 a 5kg de Uvas Passas Secas<br />8 a 10kg de Frutas Cristalizadas</p>",
                ModoDePreparo:
                    "<p>Lorem ipsum dolor sit amet, nonumes voluptatum mel ea, cu case ceteros cum. Novum commodo malorum vix ut. Dolores consequuntur in ius, sale electram dissentiunt quo te. Cu duo omnes invidunt, eos eu mucius fabellas. Stet facilis ius te, quando voluptatibus eos in. Ad vix mundi alterum, integre urbanitas intellegam vix in.</p><p>Eum facete intellegat ei, ut mazim melius usu. Has elit simul primis ne, regione minimum id cum. Sea deleniti dissentiet ea. Illud mollis moderatius ut per, at qui ubique populo. Eum ad cibo legimus, vim ei quidam fastidii.</p><p>Quo debet vivendo ex. Qui ut admodum senserit partiendo. Id adipiscing disputando eam, sea id magna pertinax concludaturque. Ex ignota epicurei quo, his ex doctus delenit fabellas, erat timeam cotidieque sit in. Vel eu soleat voluptatibus, cum cu exerci mediocritatem. Malis legere at per, has brute putant animal et, in consul utamur usu.</p><p>Te has amet modo perfecto, te eum mucius conclusionemque, mel te erat deterruisset. Duo ceteros phaedrum id, ornatus postulant in sea. His at autem inani volutpat. Tollit possit in pri, platonem persecuti ad vix, vel nisl albucius gloriatur no.</p><p>Ea duo atqui incorrupte, sed rebum regione suscipit ex, mea ex dicant percipit referrentur. Dicat luptatum constituam vix ut. His vide platonem omittantur id, vel quis vocent an. Ad pro inani zril omnesque. Mollis forensibus sea an, vim habeo adipisci contentiones ad, tale autem graecis ne sit.</p>",
                Imagem: "https://via.placeholder.com/500x300",
                Link: "#",
            },
        };
    },
      methods: {
        async carregar() {
            var $this = this;

            $this.$sdk.get('receitas?id=' + $this.$route.params.id, (ret) => {
                $this.receita = ret;
            });
        },
    },

    mounted: function(){
        this.carregar();
    },
};
</script>