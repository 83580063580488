<template>
    <div>
        <s-cabecalho />

        <section id="representacao" class="py-5">
            <header>
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <h1 class="text-decoration-underline">
                                Representação
                            </h1>
                            <p
                                class="fw-normal mt-4"
                                style="text-transform: initial"
                            >
                                <strong
                                    >ENCONTRE UM REPRESENTANTE NA SUA
                                    REGIÃO</strong
                                ><br />
                                Para ser nosso representante envie um e-mail
                                para
                                <a href="mailto:miscela@miscelaalimentos.com"
                                    >miscela@miscelaalimentos.com</a
                                >
                            </p>
                        </div>
                    </div>
                </div>
            </header>

            <div class="container">
                <div
                    class="row"
                    v-for="(estado, estIndex) in estados"
                    :key="estIndex"
                >
                    <div class="col-12">
                        <h2>{{ retorna_estado(estIndex) }}</h2>
                    </div>

                    <div
                        class="col-12 col-sm-6"
                        v-for="(representante, index) in estado"
                        :key="index"
                    >
                        <div class="caixa py-4 my-2">
                            <h3>{{ representante.Nome }}</h3>
                            <p v-html="nl2br(representante.Texto)"></p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <s-rodape />
    </div>
</template>
<script>
export default {
    data: function () {
        return {
            estados: {},
        };
    },

    metaInfo: {
        title: "Representação",
        meta: [
            { name: 'description', content: 'Encontre um representante na sua região' },
            { name: 'keywords', content: 'representante; paraná; santa catarina; itajaí; confeitaria; panificação; pré-mistura' },
        ],
    },

    methods: {
        retorna_estado(uf) {
            if (uf == "SC") return "Santa Catarina";
            if (uf == "PR") return "Paraná";
            else return uf;
        },

        async carregar() {
            var $this = this;

            $this.$sdk.get("representantes", (ret) => {
                $this.estados = ret;
            });
        },

        nl2br(str) {

            return (str + "").replace(
                /([^>\r\n]?)(\r\n|\n\r|\r|\n)/g,
                "$1<br />$2"
            );
        },
    },

    mounted: function () {
        this.carregar();
    },
};
</script>