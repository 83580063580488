var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('s-cabecalho'),(_vm.produto)?_c('section',{staticClass:"py-5",attrs:{"id":"produto"}},[_c('div',{attrs:{"id":"main"}}),_c('header',[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('h1',[_vm._v(_vm._s(_vm.produto.TituloResumo))])])])]),_vm._m(0)]),_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-10 offset-1"},[_c('div',{staticClass:"caixa p-3"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-10 offset-1 col-sm-4 offset-sm-0"},[_c('div',{staticClass:"thumb",style:({
                                        'background-image':
                                            'url(' +
                                            _vm.$sdk.prefix_images +
                                            _vm.produto.Imagem +
                                            ')',
                                    })})]),_c('div',{staticClass:"col-10 offset-1 col-sm-4 offset-sm-0"},[_c('h3',[_vm._v(_vm._s(_vm.produto.Titulo))]),_c('span',{staticClass:"qtd"},[_vm._v(_vm._s(_vm.produto.Qtd))]),_c('p',{staticClass:"pt-3"},[_c('strong',[_vm._v("INGREDIENTES")]),_c('br'),_c('small',{domProps:{"innerHTML":_vm._s(_vm.nl2br(_vm.produto.Ingredientes))}})]),_c('p',[_c('strong',[_vm._v("ALÉRGICOS:")]),_c('br'),_c('span',{domProps:{"innerHTML":_vm._s(_vm.nl2br(_vm.produto.Alergicos))}})]),(_vm.produto.ReceitaLink)?_c('router-link',{attrs:{"to":{
                                        name: 'receita',
                                        params: { id: _vm.produto.ReceitaLink },
                                    }}},[_vm._v("VER RECEITA")]):_vm._e()],1)])])])])]),_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-10 offset-1"},[_c('router-link',{staticClass:"voltar",attrs:{"to":{ name: 'produtos', hash: '#main' }}},[_vm._v("← "),_c('span',[_vm._v("Voltar a Todos os Produtos")])])],1)])])]):_vm._e(),_c('s-rodape')],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"faixa my-2"},[_c('a',{attrs:{"href":"http://wa.me/554732416624","target":"_blank"}},[_vm._v("Entre em contato já!")])])}]

export { render, staticRenderFns }