<template>
    <div>
        <s-cabecalho />

        <s-produtos subtitulo="Conheça todos os nossos produtos" :resumo="false" />

        <s-rodape />
    </div>
</template>
<script>
export default {
    metaInfo: {
        title: "Produtos",
        meta: [
            { name: 'description', content: 'Mais de 50 produtos de confeitaria e panificação.' },
            { name: 'keywords', content: 'confeitaria; panificação; padaria; mistura pré-pronta; bolo; pão; cuca; panetone; chocolate; fermento' },
        ],
    },
}
</script>