<template>
    <div>
        <s-cabecalho />

        <section id="contato" class="py-5">
            <header>
                <div class="container">
                    <div class="row">
                        <div class="col-10 offset-1 col-sm-10 offset-sm-1">
                            <h1 class="text-decoration-underline mb-4">
                                Contato
                            </h1>
                            <p
                                class="fw-normal"
                                style="text-transform: initial"
                            >
                                Entre em contanto conosco através de nosso
                                telefone, WhatsApp ou formulário:
                            </p>
                        </div>
                    </div>
                </div>
            </header>

            <main>
                <div class="container">
                    <div class="row mt-4">
                        <div class="col-10 offset-1 col-sm-4 offset-sm-2">
                            <div id="social">
                                <a href="mailto:miscela@miscelaalimentos.com.br">
                                    <img
                                        src="@/assets/images/icon-header-email.png"
                                    />
                                </a>
                                <a href="http://wa.me/554732416624">
                                    <img
                                        src="@/assets/images/icon-header-whatsapp.png"
                                    />
                                </a>
                            </div>
                            <p class="fw-bold text-uppercase contatos">
                                (47) 3241-6624<br />
                                <a href="mailto:miscela@miscelaalimentos.com.br"
                                    >miscela@miscelaalimentos.com.br</a
                                >
                            </p>

                            <p>
                                Rua Nilson Edson dos Santos, 455<br />
                                CEP 88309-400 São Vicente - Itajaí - SC
                            </p>
                            <p>
                                CNPJ 06.031.984/0001-54<br />
                                I.E. 254.694.160
                            </p>
                        </div>
                        <div class="col-10 offset-1 col-sm-4 offset-sm-0">
                            <form action="">
                                <input
                                    class="form-control"
                                    placeholder="Nome"
                                />
                                <input
                                    class="form-control"
                                    placeholder="Telefone"
                                />
                                <input
                                    class="form-control"
                                    placeholder="E-mail"
                                />
                                <textarea
                                    class="form-control"
                                    placeholder="Mensagem"
                                >
                                </textarea>

                                <button class="btn">Enviar</button>
                            </form>
                        </div>
                    </div>
                </div>

                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <p class="text-center my-5">
                                Gostaríamos de convida-lo a conhecer nosso
                                processo fabril e operacional. <br />Será um
                                prazer lhe apresentar nossa estrutura e
                                soluções. Agende uma visita.
                            </p>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3557.939560212278!2d-48.69556238426977!3d-26.905413199381623!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94d8ccec90d0cfc7%3A0x5350f3ae656a1e2a!2sR.%20Arquiteto%20Nilson%20Edson%20dos%20Santos%2C%20455%20-%20S%C3%A3o%20Vicente%2C%20Itaja%C3%AD%20-%20SC%2C%2088309-400!5e0!3m2!1spt-BR!2sbr!4v1634177892920!5m2!1spt-BR!2sbr" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
                        </div>
                    </div>
                </div>
            </main>
        </section>
        
        <s-rodape />
    </div>
</template>

<script>
export default {
    metaInfo: {
        title: "Contato",
        meta: [
            { name: 'description', content: 'Entre em contato através de telefone, whatsapp ou formulário.' },
            { name: 'keywords', content: 'contato; telefone; whatsapp; endereço; santa catarina; itajaí; confeitaria; panificação; pré-mistura' },
        ],
    },
}
</script>