<template>
    <section id="topicos">

        <div class="container">
            <div class="row">
                
                <div class="col-12 col-sm-3 offset-sm-1" v-for="(topico, index) in topicos" :key="index">
                    <div class="topico">
                        <div class="thumb" :style="{ 'background-image' : 'url(' + topico.icone + ')' }"></div>
                        <span>{{ topico.texto }}</span>
                    </div>
                </div>
                
            </div>
        </div>

    </section>
</template>
<script>
export default {
    
    data: function(){
        return {

            topicos: [
                {
                    icone: '/images/topicos/mais-de-10-anos.png',
                    texto: 'Mais de 10 anos de mercado'
                },
                {

                    icone: '/images/topicos/melhores-tecnologias-do-mercado.png',
                    texto: 'Melhores Tecnologias do Mercado'
                },
                {

                    icone: '/images/topicos/representantes.png',
                    texto: 'Representantes em Toda Santa Catarina'
                },
                {

                    icone: '/images/topicos/atuacao.png',
                    texto: 'Atuação em Panificação, confeitaria e similares'
                },
                {

                    icone: '/images/topicos/referencia.png',
                    texto: 'Referência em Pré-mistura na região'
                },
                {

                    icone: '/images/topicos/portfolio.png',
                    texto: 'Portfólio com mais de 50 produtos'
                },
                

                

            ]

        }
    }

}
</script>