<template>
    <div>
        <s-cabecalho />

        <section id="receitas-lista" class="py-5">
            <div id="main"></div>
            <header>
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <h1 class="text-decoration-underline">Receitas</h1>
                            <p
                                class="fw-normal"
                                style="text-transform: initial"
                            >
                                <strong
                                    >MODO DE USO DOS NOSSOS PRODUTOS</strong
                                ><br />
                                Você também pode <a href="https://downloads.miscelaalimentos.com.br/ebook.pdf" target="_blank"
                                    >baixar nosso E-BOOK</a> com todas as receitas.
                                
                            </p>
                        </div>
                    </div>
                </div>
            </header>

            <div class="container">
                <div class="row">
                    <div class="col-6 col-sm-4" v-for="(receita, index) in receitas" :key="index">
                        <div class="caixa">
                            <div class="thumb" :style="{ 'background-image' : 'url('+ $sdk.prefix_images + receita.Imagem +')' }"></div>
                            <h3>{{ receita.Titulo }}</h3>
                            <router-link :to="{ name: 'receita', params: { id: receita.Link }, hash: '#main' }">Veja mais</router-link>
                        </div>
                    </div>
                </div>

    
            </div>

        </section>

        <s-ebook />

        <s-rodape />
    </div>
</template>
<script>
export default {
    data: function () {
        return {
            receitas: [],
        };
    },

    metaInfo: {
        title: "Receitas",
        meta: [
            { name: 'description', content: 'Confira todas as receitas que podem ser feitas com nossos produtos, ou baixe nosso e-book.' },
            { name: 'keywords', content: 'e-book; receitas; bolo; pão; donuts; sonho; cuca; panetone' },
        ],
    },
    methods: {
        async carregar() {
            var $this = this;

            $this.$sdk.get('receitas', (ret) => {
                $this.receitas = ret;
            });
        },
    },

    mounted: function(){
        this.carregar();
    },


};
</script>