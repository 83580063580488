<template>
    <section id="receitas">

        <header>
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <h1>Receitas</h1>
                        <p>Veja nossas receitas e baixe o nosso e-book</p>
                    </div>
                </div>
            </div>
        </header>

        <main>
            <div class="container">

                <div class="row">
                    <div class="col-10 offset-1">
                        <div id="receitas">
                            <div class="receita" v-for="(receita, index) in receitas" :key="index">
                                
                                <div class="box">
                                    <div class="thumb" :style="{ 'background-image' : 'url(' + $sdk.prefix_images + receita.Imagem + ')'}"></div>
                                    <h3>{{ receita.Titulo }}</h3>
                                    <router-link :to="{ name : 'receita', params: { id: receita.Link } }">Leia mais</router-link>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>

                 <div class="row">
                    <div class="col-10 offset-1 py-3">
                        <router-link class="link" :to="{ name: 'receitas' }">Ver todas as receitas</router-link>
                    </div>
                 </div>

            </div>
        </main>

    </section>
</template>
<script>
export default {
    data: function(){
        return {
            receitas:[]
        }
    },

    methods: {
        async carregar() {
            var $this = this;

            $this.$sdk.get('receitas?l=3', (ret) => {
                $this.receitas = ret;
            });
        },

        
    },

    mounted: function(){
        this.carregar();
    },
}
</script>