<template>
    <div>
        <s-cabecalho />
        <s-topicos />
        <s-produtos :top="true" />
        <s-receitas />
        <s-ebook />
        <s-rodape />
    </div>
</template>
<script>
export default {
    metaInfo: {
        title: "Home",
        meta: [
            { name: 'description', content: 'Miscela há mais de 10 anos no mercado de confeitaria e panificação. Referência em pré-misturas em Santa Catarina.' },
            { name: 'keywords', content: 'confeitaria; panificação; padaria; santa catarina; pré-mistura; bolos; pães; panetones' },
        ],
    },
};
</script>