<template>
    <div>
        <header id="header">
            <router-link :to="{ name: 'home' }">
                <img src="@/assets/images/logo.png" id="logo" />
            </router-link>

            <ul id="social" class="d-none d-sm-flex">
                <li>
                    <a href="http://wa.me/554732416624" target="_blank">
                        <img src="@/assets/images/icon-header-whatsapp.png" />
                    </a>
                </li>
                <li>
                    <a href="mailto:miscela@miscelaalimentos.com.br">
                        <img src="@/assets/images/icon-header-email.png" />
                    </a>
                </li>
            </ul>

            <!-- MENU DESKTOP -->
            <div class="container d-none d-sm-block">
                <div class="row">
                    <div class="col-12 col-xxl-10 offset-0 offset-xxl-1">
                        <nav>
                            <router-link
                                v-for="(item, index) in menu" :key="index"
                                :to="{ name: item.name }"
                                :class="{ ativo: $route.name == item.name }"
                                >{{ item.legenda }}</router-link
                            >
                        </nav>
                    </div>
                </div>
                
            </div>

            <!-- MENU MOBILE -->
            <div class="d-block d-sm-none">
                <img src="@/assets/images/menu.png" id="menu-mobile-btn" @click="menu_mobile()" />
            </div>
            <nav class="d-block d-sm-none" id="menu-mobile" v-if="menu_mobile_aberto">
                <router-link
                    v-for="(item, index) in menu" :key="index"
                    :to="{ name: item.name }"
                    :class="{ ativo: $route.name == item.name }"
                    >{{ item.legenda }}</router-link
                >

                <ul class="d-flex">
                    <li>
                        <a href="http://wa.me/554732416624" target="_blank">
                            <img src="@/assets/images/icon-header-whatsapp.png" />
                        </a>
                    </li>
                    <li>
                        <a href="mailto:miscela@miscelaalimentos.com.br">
                            <img src="@/assets/images/icon-header-email.png" />
                        </a>
                    </li>
                </ul>
                
            </nav>
            
        </header>

        <section id="banners">
            <carousel
                :per-page="1"
                :navigate-to="0"
                :autoplay="true"
                :loop="true"
                :navigationEnabled="true"
                pagination-position="bottom-overlay"
            >
                <slide v-for="(banner, index) in banners" :key="index">
                    <a :href="banner.link" class="item">
                        <div
                            :style="{
                                backgroundImage: `url(${banner.imagem})`,
                            }"
                        ></div>
                    </a>
                </slide>
            </carousel>
        </section>
    </div>
</template>
<script>
export default {
    data() {
        return {
            banners: [],
            errorMessage: null,
            s: null,
            menu_mobile_aberto: false,
            menu: [
                {
                    name: 'home',
                    legenda: 'Home'
                },
                {
                    name: 'quem-somos',
                    legenda: 'SObre nós'
                },
                {
                    name: 'produtos',
                    legenda: 'Produtos'
                },
                /*
                {
                    name: 'representantes',
                    legenda: 'Representação'
                },*/
                {
                    name: 'receitas',
                    legenda: 'Receitas'
                },
                {
                    name: 'contato',
                    legenda: 'Contato'
                }
            ]
        };
    },
    mounted: function () {
        //this.carregar();

        if (this.$route.name == "contato") {
            this.banners = [
                {
                    link: "",
                    imagem: "/images/banners/contato.png",
                },
            ];
        } else if (this.$route.name == "produtos" || this.$route.name == "produto") {
            this.banners = [
                {
                    link: "",
                    imagem: "/images/banners/produtos.png",
                },
            ];
        } else if (this.$route.name == "receitas" || this.$route.name == "receita") {
            this.banners = [
                {
                    link: "",
                    imagem: "/images/banners/receitas.png",
                },
            ];
        } else if (this.$route.name == "representantes") {
            this.banners = [
                {
                    link: "",
                    imagem: "/images/banners/representantes.png",
                },
            ];
        } else if (this.$route.name == "quem-somos") {
            this.banners = [
                {
                    link: "",
                    imagem: "/images/banners/quem-somos.png",
                },
            ];
        } else {
            this.banners = [
                {
                    link: "/produtos",
                    imagem: "/images/banners/01.png",
                },
                {
                    link: '/quem-somos',
                    imagem: "/images/banners/03.png",
                },
                {
                    link: '/produtos',
                    imagem: "/images/banners/04.png",
                },
            ];
        }
    },
    methods: {

        menu_mobile(){
            this.menu_mobile_aberto = (!this.menu_mobile_aberto);
        }
        /*
        async carregar() {
            var $this = this;
            $this.errorMessage = '';
            $this.$sdk.get('banners/home').then((ret) => {
                $this.banners = ret;
            }).catch(err => {
                $this.errorMessage = err;
            });
            
        }
        */
    },
};
</script>