var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"my-5",attrs:{"id":"produtos"}},[_c('div',{attrs:{"id":"main"}}),_c('header',[_c('div',{staticClass:"faixa mb-4",domProps:{"innerHTML":_vm._s(_vm.faixa)}}),_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('h1',[_vm._v(_vm._s(_vm.titulo))]),_c('p',[_vm._v(_vm._s(_vm.subtitulo))])])])])]),_c('main',[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-10 offset-1"},[(_vm.resumo)?_c('carousel',{attrs:{"per-page":_vm.cols,"navigate-to":0,"autoplay":false,"loop":true,"navigationEnabled":true,"paginationEnabled":false,"navigationNextLabel":"→","navigationPrevLabel":"←"}},_vm._l((_vm.lista_de_produtos),function(produto,index){return _c('slide',{key:index},[_c('div',{staticClass:"produto"},[_c('div',{staticClass:"thumb",style:({
                                        'background-image':
                                            'url(' + _vm.$sdk.prefix_images + produto.Imagem + ')',
                                    })}),_c('h3',[_vm._v(_vm._s(produto.TituloResumo))]),_c('router-link',{attrs:{"to":{
                                        name: 'produto',
                                        params: { id: produto.Link },
                                        hash: '#main'
                                    }}},[_vm._v("Ver mais")])],1)])}),1):_vm._e(),(!_vm.resumo)?_c('div',{staticClass:"p-3",attrs:{"id":"lista-produtos"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{attrs:{"id":"categorias"}},[_c('span',{class:{ ativo: _vm.filtro == null },on:{"click":function($event){_vm.filtro = null}}},[_vm._v("Todos")]),_vm._l((_vm.lista_de_categorias),function(cat,index){return _c('span',{key:index,class:{ ativo: _vm.filtro == cat },on:{"click":function($event){_vm.filtro = cat}}},[_vm._v(" "+_vm._s(cat)+" ")])})],2)])]),_c('div',{staticClass:"row"},_vm._l((_vm.lista_de_produtos),function(produto,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(
                                    !_vm.filtro || _vm.filtro == produto.Categoria
                                ),expression:"\n                                    !filtro || filtro == produto.Categoria\n                                "}],key:index,staticClass:"col-12 col-sm-3 py-2"},[_c('div',{staticClass:"produto"},[_c('div',{staticClass:"thumb",style:({
                                            'background-image':
                                                'url(' +
                                                _vm.$sdk.prefix_images + produto.Imagem +
                                                ')',
                                        })}),_c('h3',[_vm._v(_vm._s(produto.Titulo))]),_c('router-link',{attrs:{"to":{
                                            name: 'produto',
                                            params: { id: produto.Link },
                                            hash: '#main'
                                        }}},[_vm._v("Ver mais")])],1)])}),0)]):_vm._e()],1)])]),_c('div',{staticClass:"container"},[(_vm.resumo)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('router-link',{staticClass:"link",attrs:{"to":{ name: 'produtos' }}},[_vm._v("Ver todos os produtos")])],1)]):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }