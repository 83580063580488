<template>
    <section id="ebook">
        <div class="container">
            <div class="row">
                <div class="col-8 offset-2 col-sm-3 offset-sm-1">
                    <img src="/images/ebook.png" />
                </div>
                <div class="col-12 col-sm-3">
                    <button class="btn" @click="baixar">
                        Clique e baixe o ebook gratuitamente
                    </button>
                    <p>
                        Nesse E-book você encontra diversas receitas especiais
                        formuladas por nossos especialistas para aproveitar
                        ainda melhor nossos produtos.
                    </p>
                </div>
                <div class="col-12 col-sm-4">
                    <img src="/images/receita-bg.png" />
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default{
    methods: {
        baixar(){
            window.open('https://downloads.miscelaalimentos.com.br/ebook.pdf', '_blank');
        }
    }
}
</script>