export default {
    namespaced: true,

    state: {
        user: null
    },

    mutations: {
    },

    actions: {
    }

};