import Home             from "./pages/home"
import QuemSomos        from "./pages/quem-somos"
import Produtos         from "./pages/produtos"
import Produto          from "./pages/produto"
import Representacao    from "./pages/representacao"
import Receitas         from "./pages/receitas"
import Receita          from "./pages/receita"
import Contato          from "./pages/contato"


const routes = [{
        name: 'home',
        path: '/',
        component: Home
    },
    {
        name: 'quem-somos',
        path: '/quem-somos',
        component: QuemSomos
    },
    {
        name: 'produtos',
        path: '/produtos',
        component: Produtos
    },
    {
        name: 'produto',
        path: '/produto/:id',
        component: Produto
    },
    {
        name: 'representantes',
        path: '/representantes',
        alias: '/representacao',
        component: Representacao
    },
    {
        name: 'receitas',
        path: '/receitas',
        alias: '/receita',
        component: Receitas
    },
    {
        name: 'receita',
        path: '/receita/:id',
        component: Receita
    },
    {
        name: 'contato',
        path: '/contato',
        component: Contato
    }

]

export default routes