<template>
    <div id="app">
        <router-view/>
    </div>
</template>

<script>
export default {
    name: 'App',   
    metaInfo: {
      title: 'Miscela Alimentos',
      titleTemplate: '%s | Miscela Alimentos',
      meta: [{
            name: 'robots',
            content: 'index, follow',
        }]
    }    
}
</script>

<style lang="less">
    @import "@/assets/less/vars.less";
    @import "@/assets/less/responsive.less";
    @import "@/assets/less/base.less";
</style>