<template>
    <section id="produtos" class="my-5">
        <div id="main"></div>
        <header>

            <div class="faixa mb-4" v-html="faixa"></div>

            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <h1>{{ titulo }}</h1>
                        <p>{{ subtitulo }}</p>
                    </div>
                </div>
            </div>

        </header>

        <main>
            <div class="container">
                <div class="row">
                    <div class="col-10 offset-1">
                        <carousel
                            v-if="resumo"
                            :per-page="cols"
                            :navigate-to="0"
                            :autoplay="false"
                            :loop="true"
                            :navigationEnabled="true"
                            :paginationEnabled="false"
                            navigationNextLabel="→"
                            navigationPrevLabel="←"
                        >
                            <slide
                                v-for="(produto, index) in lista_de_produtos"
                                :key="index"
                            >
                                <div class="produto">
                                    <div
                                        class="thumb"
                                        :style="{
                                            'background-image':
                                                'url(' + $sdk.prefix_images + produto.Imagem + ')',
                                        }"
                                    ></div>
                                    <h3>{{ produto.TituloResumo }}</h3>
                                    <router-link
                                        :to="{
                                            name: 'produto',
                                            params: { id: produto.Link },
                                            hash: '#main'
                                        }"
                                        >Ver mais</router-link
                                    >
                                </div>
                            </slide>
                        </carousel>

                        <div id="lista-produtos" class="p-3" v-if="!resumo">
                            <div class="row">
                                <div class="col-12">
                                    <div id="categorias">
                                        <span
                                            @click="filtro = null"
                                            :class="{ ativo: filtro == null }"
                                            >Todos</span
                                        >
                                        <span
                                            v-for="(
                                                cat, index
                                            ) in lista_de_categorias"
                                            :key="index"
                                            @click="filtro = cat"
                                            :class="{ ativo: filtro == cat }"
                                        >
                                            {{ cat }}
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div
                                    class="col-12 col-sm-3 py-2"
                                    v-for="(
                                        produto, index
                                    ) in lista_de_produtos"
                                    :key="index"
                                    v-show="
                                        !filtro || filtro == produto.Categoria
                                    "
                                >
                                    <div class="produto">
                                        <div
                                            class="thumb"
                                            :style="{
                                                'background-image':
                                                    'url(' +
                                                    $sdk.prefix_images + produto.Imagem +
                                                    ')',
                                            }"
                                        ></div>
                                        <h3>{{ produto.Titulo }}</h3>
                                        <router-link
                                            :to="{
                                                name: 'produto',
                                                params: { id: produto.Link },
                                                hash: '#main'
                                            }"
                                            >Ver mais</router-link
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container">
                <div class="row" v-if="resumo">
                    <div class="col-12">
                        <router-link :to="{ name: 'produtos' }" class="link"
                            >Ver todos os produtos</router-link
                        >
                    </div>
                </div>
                <!-- <div class="row">
                    <div class="col-12">
                        <router-link
                            :to="{ name: 'representantes' }"
                            class="btn"
                            >Onde Encontrar</router-link
                        >
                    </div>
                </div> -->
            </div>
        </main>
    </section>
</template>
<script>
export default {
    props: {
        titulo: {
            type: String,
            default: "Produtos",
        },
        subtitulo: {
            type: String,
            default: "Top 10 - Mais Vendidos do Mês",
        },
        faixa: {
            type: String,
            default: '<a href="http://wa.me/554732416624" target="_blank">Entre em contato já!</a>',
        },
        resumo: {
            type: Boolean,
            default: true,
        },
        top: {
            type: Boolean,
            default: false
        }
    },
    data: function () {
        return {
            filtro: null,
            produtos: [],
        };
    },

    methods: {
        async carregar() {
            var $this = this;

            var uri = (this.top)?'produtos?top=sim': 'produtos';
            $this.$sdk.get(uri, (ret) => {
                $this.produtos = ret;
            });
        },
    },

    mounted: function(){
        this.carregar();
    },

    computed: {
        lista_de_produtos: function () {
            if (this.resumo) {
                return this.produtos.slice(0, 8);
            } else {
                return this.produtos;
            }
        },

        lista_de_categorias: function () {
            var cats = [];

            this.produtos.forEach((prod) => {
                cats.push(prod.Categoria);
            });

            return [...new Set(cats)];
        },

        cols(){
            if(window.innerWidth < 575){
                return 1
            } else {
                return 4;
            }
        }
    },
};
</script>