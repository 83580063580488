<template>
    <div>
        <s-cabecalho />

        <section id="produto" class="py-5" v-if="produto">
            <div id="main"></div>
            <header>
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <h1>{{ produto.TituloResumo }}</h1>
                        </div>
                    </div>
                </div>

                <div class="faixa my-2"><a href="http://wa.me/554732416624" target="_blank">Entre em contato já!</a></div>
            </header>

            <div class="container">
                <div class="row">
                    <div class="col-10 offset-1">
                        <div class="caixa p-3">
                            <div class="row">
                                <div class="col-10 offset-1 col-sm-4 offset-sm-0">
                                    <div
                                        class="thumb"
                                        :style="{
                                            'background-image':
                                                'url(' +
                                                $sdk.prefix_images +
                                                produto.Imagem +
                                                ')',
                                        }"
                                    ></div>
                                </div>
                                <div class="col-10 offset-1 col-sm-4 offset-sm-0">
                                    <h3>{{ produto.Titulo }}</h3>
                                    <span class="qtd">{{ produto.Qtd }}</span>
                                    <p class="pt-3">
                                        <strong>INGREDIENTES</strong><br />
                                        <small
                                            v-html="nl2br(produto.Ingredientes)"
                                        ></small>
                                    </p>
                                    <p>
                                        <strong>ALÉRGICOS:</strong><br />
                                        <span
                                            v-html="nl2br(produto.Alergicos)"
                                        ></span>
                                    </p>
                                    <router-link
                                        v-if="produto.ReceitaLink"
                                        :to="{
                                            name: 'receita',
                                            params: { id: produto.ReceitaLink },
                                        }"
                                        >VER RECEITA</router-link
                                    >
                                </div>
                                <!--
                                <div class="col-10 offset-1 col-sm-4 offset-sm-0">
                                    <div
                                        class="thumb"
                                        :style="{
                                            'background-image':
                                                'url(' +
                                                $sdk.prefix_images +
                                                produto.Tabela +
                                                ')',
                                        }"
                                    ></div>
                                </div>
                                -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container">
               <!-- <div class="row">
                    <div class="col-12">
                        <router-link
                            :to="{ name: 'representantes' }"
                            class="btn"
                            >Onde Encontrar</router-link
                        >
                    </div>
                </div> -->

                <div class="row">
                    <div class="col-10 offset-1">
                        <router-link
                            :to="{ name: 'produtos', hash: '#main' }"
                            class="voltar"
                            >←
                            <span>Voltar a Todos os Produtos</span></router-link
                        >
                    </div>
                </div>
            </div>
        </section>

        <s-rodape />
    </div>
</template>
<script>
export default {
    metaInfo: function () {

        
        return {
            title: this.seo_titulo,
            meta: [
                {
                    name: "description",
                    content:
                        "Mais de 50 produtos de confeitaria e panificação.",
                },
                {
                    name: "keywords",
                    content:
                        "confeitaria; panificação; padaria; mistura pré-pronta; bolo; pão; cuca; panetone; chocolate; fermento",
                },
            ],
        };
    },

    data: function () {
        return {
            produto: null,
        };
    },

    computed: {
        seo_titulo: function(){
            if(this.produto)
            return this.produto.TituloResumo + ' (produto)';
            else
                return 'Produto';
        }
    },

    methods: {
        async carregar() {
            var $this = this;

            $this.$sdk.get(
                "produtos",
                (ret) => {
                    $this.produto = ret;
                },
                $this.$route.params.id
            );
        },
        nl2br(str) {
            return (str + "").replace(
                /([^>\r\n]?)(\r\n|\n\r|\r|\n)/g,
                "$1<br />$2"
            );
        },
    },

    mounted: function () {
        this.carregar();
    },
};
</script>