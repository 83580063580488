import Vue      from 'vue'
import App      from './App.vue'
import store    from './stores';

/* Router */
import router from './router'
Vue.use(router)

/* VueMeta */
import VueMeta from 'vue-meta'
Vue.use(VueMeta, {
    keyName: 'metaInfo',
    attribute: 'data-vue-meta',
    ssrAttribute: 'data-vue-meta-server-rendered',
    tagIDKeyName: 'vmid',
    refreshOnceOnNavigation: true
  })

/* Axios */
import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)

Vue.config.productionTip = false

/* ue-sweetalert2 */
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);

/* Vue-Carousel */
import VueCarousel from 'vue-carousel';
Vue.use(VueCarousel);

/* SDK */
import sdk from "./sdk"
Vue.use(sdk);

/* VueI18n */
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

//carrega idiomas disponíveis
import pt_BR from '../theme/i18n/pt_BR.js';

const i18n = new VueI18n({
    locale: 'pt_BR',
    messages: {
        pt_BR
    }
})



require("./sections")
require("./components")

new Vue({
  render: h => h(App),
  router,
  store,
  i18n
}).$mount('#app')
